<template>
  <div>
    <AppHeader title="送样查询"/>
    <Panel class="tabs search-panel" :title="false">
      <div slot="content">
        <div class="line">
          <input class="line-input" id="eqName" @click="showEquipments" v-model="equipment"
                 @keydown="preventDefault" placeholder="仪器名称"/>
          <div class="flex-dir-row mt10">
            <div class="flex-item-auto">
              <input class="line-input" id="name" v-model="name" placeholder="样品名称"/>
            </div>
            <div class="btn btn-small green ml5" @click="reset">重设</div>
            <div class="btn btn-small green ml5" @click="initEvents">查询</div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel class="tabs mt15" :title="false">
      <div slot="content" v-infinite-scroll="loadEvents"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(sample,idx) of samples">
          <div class="flex-dir-row">
            <div class="line-label">送样人：</div>
            {{ sample.realName }}
            <div :class="`flex-item-auto sample-line-status ${sample.statusClass}`">
              <div class="sample-line-status-circle"></div>
              {{ sample.statusText }}
            </div>
          </div>

          <div class="flex-dir-row">
            <div class="line-label">仪器名称：</div>
            {{ sample.equipment }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">样品名称：</div>
            {{ sample.name }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">样品数量：</div>
            {{ sample.sampleNum }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">送样日期：</div>
            {{ dateFormat(sample.applyAt) }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">送样费用：</div>
            {{ (sample.actualCost ? sample.actualCost : sample.cost) / 100.0 }}元
            <div class="flex-item-auto" style="display:flex;justify-content: end;" v-if="sample.statusOriginal===6">
              <div class="btn btn-small green download-btn" @click="download(sample.attachments)" v-if="sample.attachments.length>0">报告下载</div>
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="samples.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-datetime-picker
        v-model="dateVal"
        type="datetime"
        @confirm="handleConfirm"
        ref="picker"
    >
    </mt-datetime-picker>

    <mt-popup
        v-model="equipmentsVisible"
        position="bottom"
    >
      <mt-picker :slots="equipmentSlots" @change="onEquipmentChange" value-key="text"
                 v-if="equipmentsVisible"></mt-picker>
    </mt-popup>

  </div>
</template>

<style lang="scss" scoped>
.sample-line-title {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.sample-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #999999;

  &.enabled {
    color: #3b5998;

    .sample-line-status-circle {
      background: #3b5998;
    }
  }

  &.auditing {
    color: #8CC152;

    .sample-line-status-circle {
      background: #8CC152;
    }
  }

  &.cancel {
    color: #434A54;

    .sample-line-status-circle {
      background: #434A54;
    }
  }

  &.finish {
    color: #3b5998;

    .sample-line-status-circle {
      background: #3b5998;
    }
  }
}

.sample-line-status-circle {
  width: 6px;
  height: 6px;
  background: #999999;
  border-radius: 3px;
  margin-right: 4px;
}

.download-btn {
  width: 80px;
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, DatetimePicker, Picker} from 'mint-ui';
import dict from "@/plugins/dict";
import store from "@/plugins/store";
import {mapState} from 'vuex'

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    DatetimePicker,
    Picker
  },
  data() {
    return {
      page: 1,
      samples: [],
      loading: false,
      noMore: false,
      beginAt: null,
      endAt: null,
      equipment: null,
      equipmentId: null,
      dateVal: new Date(),
      pickerField: '',
      equipmentsVisible: false,
      name: ''
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    equipmentSlots(state) {
      return [{values: state.equipments}]
    }
  }),
  methods: {
    onEquipmentChange(picker, values) {
      this.equipment = values[0].text
      this.equipmentId = values[0].id
    },
    showEquipments() {
      this.equipmentsVisible = true
    },
    showPicker(pickerField) {
      this.dateVal = new Date()
      this.pickerField = pickerField
      this.$refs.picker.open()
    },
    handleConfirm(value) {
      if (this.pickerField === 'beginAt') {
        this.beginAt = this.$moment(value).valueOf()
      } else {
        this.endAt = this.$moment(value).valueOf()
      }
    },
    reset() {
      this.page = 1
      this.loading = false
      this.beginAt = null
      this.endAt = null
      this.equipment = ''
      this.equipmentId = ''
      this.name = ''
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YY-MM-DD HH:mm')
      }
      return ''
    },
    initEvents() {
      this.samples = []
      this.page = 1
      this.noMore = false
      this.loadEvents()
    },
    //status_sample:
    //'0': 审核通过
    //'2': 待审核
    //'5': 拒绝
    //'6': 结算完成
    wrapSample(sample) {
      if (sample.statusOriginal === 0) {
        sample.statusText = '审核通过'
        sample.statusClass = 'enabled'
      } else if (sample.statusOriginal === 2) {
        sample.statusText = '待审核'
        sample.statusClass = 'auditing'
      } else if (sample.statusOriginal === 5) {
        sample.statusText = '已拒绝'
        sample.statusClass = 'cancel'
      } else {
        sample.statusText = '结算完成'
        sample.statusClass = 'finish'
      }
      return sample
    },
    loadEvents() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      data.append('statusIn', '0,2,5')
      data.append('self', 'true')
      this.equipmentId && data.append('equipmentId', this.equipmentId)
      this.name && data.append('name', `${this.name}`)
      this.$http.post(`/ucenter/sample/query`, data).then(res => {
        if (res.data && res.data.length > 0) {
          const samples = res.data.map(lic => {
            return that.wrapSample(lic)
          })
          that.samples = that.samples.concat(samples)
          that.loading = false
          that.page = that.page + 1
          console.log(that.samples)
        } else {
          this.noMore = true
        }
      })
    },
    preventDefault(e) {
      e.preventDefault()
    },
    download(attachments) {
      if (attachments && attachments.length > 0) {
        for (let attachment of attachments) {
          window.open(`${this.apiUrl}/fs/${attachment}`);
        }
      }
    }
  },
  mounted() {
    this.initEvents()
  }
}
</script>
